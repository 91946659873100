.navbar-vnns {
  .navbar-brand {
    @media only screen and (max-width: 576px) {
      font-size: 10px;
    }
    @media only screen and (min-width: 576px) {
      font-size: 10px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 10px;
    }
    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1400px) {
      font-size: 16px;
    }
  }

  img {
    @media only screen and (max-width: 576px) {
      width: 27px !important;
      height: 10px !important;
    }
    @media only screen and (min-width: 576px) {
      width: 27px !important;
      height: 10px !important;
    }
    @media only screen and (min-width: 768px) {
      width: 27px !important;
      height: 10px !important;
    }
    @media only screen and (min-width: 992px) {
      width: 80px !important;
      height: 20px !important;
    }
    @media only screen and (min-width: 1200px) {
      width: 80px !important;
      height: 20px !important;
    }
    @media only screen and (min-width: 1400px) {
      width: 80px !important;
      height: 20px !important;
    }
  }
}