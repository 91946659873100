.autocomplete-group {
  ::-webkit-scrollbar {
    height: 12px !important;              /* height of horizontal scrollbar ← You're missing this */
    width: 12px !important;               /* width of vertical scrollbar */
    border: 12px solid #e6e6e6 !important;
  }

  .admon_autocomplete__control {
    border: 1px solid red;
  }
}