.table-antd {
  table {
    white-space: pre
  }

  thead[class*="ant-table-thead"] th{
    // background-color: #c5cbec;
    background-color: #e6e6e6;
  }
  
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
  }
  
  .table-summary table > tbody > tr:last-child {
    font-weight: bold;
  }

  ::-webkit-scrollbar {
    height: 12px !important;              /* height of horizontal scrollbar ← You're missing this */
    width: 12px !important;               /* width of vertical scrollbar */
    border: 12px solid #d5d5d5 !important;
  }
}