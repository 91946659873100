.login {
  background-color: #d9d9d9;
  padding-top: 60px;
  height: 100vh;

  .row {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .brand-name {
    font-weight: bold;
    font-size: 20px;
  }
}